// eslint-disable-next-line @nx/enforce-module-boundaries
import { getConfig } from "@bu/lib-config";

import { setRemoteDefinitions } from "./remoteModules";

export const getMfeManifest = () => {
  const config = getConfig();

  // REMINDER: Add corresponding module declation to `remotes.d.ts`
  return {
    "app-admin": config.MFE_MANIFEST_APP_ADMIN,
    "app-artist-portal": config.MFE_MANIFEST_APP_ARTIST_PORTAL,
  };
};

export const setupMfe = () => {
  const manifest = getMfeManifest();
  setRemoteDefinitions(manifest);
};
