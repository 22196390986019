import { AppConfiguration } from "./appConfiguration";

export const config: AppConfiguration = {
  ENV_NAME: "development",

  AZURE_AI_DEBUG: "false",
  AZURE_AI_CONNECTIONSTRING:
    "InstrumentationKey=1760d400-bd51-4886-8292-70bec32cd36a;IngestionEndpoint=https://canadacentral.livediagnostics.monitor.azure.com;LiveEndpoint=https://canadacentral-0.in.applicationinsights.azure.com",

  AZURE_B2C_CLIENTID: "316ddd4d-2f3e-43be-b779-ff4a2b61ba9b",
  AZURE_B2C_AUTHORITY: `https://adbeatsuniondev.b2clogin.com/9c958150-a092-43bd-82ec-9beb456a013e/B2C_1_adbeatsuniondev`,
  AZURE_B2C_KNOWNAUTHORITY1: "adbeatsuniondev.b2clogin.com",
  AZURE_B2C_BASEURL_SCOPE: "https://adbeatsuniondev.onmicrosoft.com/api2",
  AZURE_B2C_REDIRECTURI: "/",
  AZURE_B2C_POSTLOGOUTREDIRECTURI: "/",
  AZURE_B2C_CACHELOCATION: "localstorage",
  AZURE_B2C_STOREAUTHSTATEINCOOKIE: "true",

  API_BASEURL_IDENTITY: "http://localhost:3333/identity",
  API_BASEURL_LEGACY: "http://localhost:3333/legacy",
  API_BASEURL_MUSIC: "http://localhost:3333/music",

  MFE_MANIFEST_APP_ADMIN: "http://localhost:4202",
  MFE_MANIFEST_APP_ARTIST_PORTAL: "http://localhost:4201",
};
